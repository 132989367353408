          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  
  

  
    
      <v-toolbar tile dark color="accent" class="stickytopbanner2" width="100%" id="recordtoolbar">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
          <v-list-item @click="ViewForms()"  class="listoutline">
            <v-list-item-title  class="subtleoverline">
              Forms
            </v-list-item-title>
          <v-list-item-action>
              <v-icon color="warning">mdi-format-line-style</v-icon>
            </v-list-item-action>
          </v-list-item>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="UpdateEnquiry()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditEnquiry()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="AssignProcess()"  class="listoutline">
      <v-list-item-title  class="subtleoverline">
        Assign Process
      </v-list-item-title>
    <v-list-item-action>
        <v-icon class="actionicon" color="warning">mdi-swap-vertical-variant</v-icon>
      </v-list-item-action>
    </v-list-item>
        <v-list-item @click="ViewEnquiryProcessHistory()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Process History
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="warning">mdi-timeline-text-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
            <v-list-item @click="Button1001002Buttondialog = !Button1001002Buttondialog"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Convert to Sale
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="warning">mdi-developer-board</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title class="hidden-sm-and-down">
     <span class="headline" >Enquiry - </span><span class="headline font-weight-light">{{ Name  }}</span>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2">
    <v-btn @click="UpdateEnquiry()" color="green" dark
      fab
      small                
      top
      right><v-icon>mdi-content-save</v-icon></v-btn>
  </v-layout>
  
<v-dialog v-model="Button1001002Buttondialog" max-width="400px">
<v-layout class="justify-center">
   <v-card min-width=400px max-width=600px>
        <v-card-title>
          Name
        </v-card-title>
        <v-card-text>
          <v-list>                            
            <v-list-item>
              <v-list-item-content>
                Owner
                </v-list-item-content>                             
                <v-list-item-content>
                  <v-layout class="justify-end" >
                  <v-icon color="pop" v-if="!this.Owner">mdi-cancel</v-icon>
                  <v-icon color="green" v-if="this.Owner">mdi-check</v-icon>
                  </v-layout>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="Button1001002Button()">Convert to Sale</v-btn>
        </v-card-actions>
      </v-card>
      </v-layout>
</v-dialog>


  
    <v-dialog v-model="StepNumber1001002dialog" persistent>
    <v-snackbar v-model="CreateSalesOrdersnackbar" :timeout="4000" top color="success">
          <span>Awesome! You added a Vehicle Form. You can view the newly created record <a :href="'/VehicleForm/'+NewVehicleFormID" target="_blank">here</a></span>
          <v-btn color="white" @click="CreateSalesOrdersnackbar= false">Close</v-btn>
        </v-snackbar>
      <v-window  v-model="step">
    <v-window-item :value="1">
      <v-layout class="justify-center">
          <v-card min-width=400px max-width=600px>
            <v-card-title>
              <span class="headline"><strong>Step {{step}} of 1</strong> - Create Sales Order</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field id="Title"  v-model="CreateSalesOrderTitle" color="red" :rules="[rules.required]" label="* Title"></v-text-field>
                    
                    
                                            
                    
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
                <v-btn
                  :disabled="step === 1"
                  text
                  @click="step--"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="step === 2"
                  color="primary"
                  depressed
                  @click="CreateSalesOrder1000102(), step++"
                >
                  Create and Next
                </v-btn>
              </v-card-actions>
          </v-card>
      </v-layout>
        </v-window-item>
      </v-window>
      </v-dialog>
    
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Enquiry help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingEnquiry" tile  width="100%" class="stickytopbanner3" id="recordownershipbanner1">
  <v-layout row class="justify-start" id="recordbanner">
      <v-col v-if="Owner">
        <strong>Owner: </strong>{{ Owner.Name+' '+Owner.Surname}}
      </v-col>
    </v-layout>    
</v-card>
<v-card v-if="EditingEnquiry" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">Enquiry - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Name" label="Name" />
  </v-card-text>
  </v-img>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <strong class="accent--text">Owner:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" @change="AssignBU()" v-model="Owner" return-object></v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card> 
  
  
  

  

  

    <v-dialog v-model="SelectProcessDialog" max-width="400">
  <v-card>
    <v-card-title>
      Assign Process
    </v-card-title>
    <v-select return-object @change="AssignProcessConfirm()" v-model="ActiveProcess" item-text="DisplayName" :items="EntityProcesses" label="Process"></v-select>
  </v-card>
</v-dialog>
    
    <v-stepper v-model="StageID"  :dark="AppisDarkMode">

      <v-stepper-header>
        <span v-for="stage in ProcessStages" :key="stage.Number">
          <v-stepper-step  editable :step="stage.Number" :complete="StageID > stage.Number" @click="updateStatus(stage.Number)">{{stage.Name}}
          </v-stepper-step>
          <v-divider></v-divider></span>
        
      </v-stepper-header>

  </v-stepper>
  
   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><span class="secondary--text headline" id="Basic"></span></v-layout>
 
           <v-layout row v-if="!EditingEnquiry">
        <v-col class="my-5 slightmargin" cols="12" sm="12">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                <v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Name</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Name}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field  v-model="Name"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Surname</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Surname}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field  v-model="Surname"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Email</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                     <a :href="'mailto:'+Email" target="_top">{{Email}}</a>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Telephone Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col> 
                       <a :href="'callto:'+Telephone_Number" target="_top">{{Telephone_Number}}</a>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Enquiry</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Enquiry}}</h4>
                      <v-textarea v-if="EditingSection1" v-model="Enquiry"></v-textarea>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Status</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1 && typeof Status !== 'undefined'" class="light-blue--text">{{Status.Name}}</h4>
                      
                    <span v-if="EditingSection1"><v-combobox
                        v-model="Status"


                        :items="StatusOptions"
                        item-text="Name"
                        label="Status Options"
                        hide-selected
                        small-chips
                        solo
                        flat
                      ></v-combobox>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Status Reason</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1 && typeof Status_Reason !== 'undefined'" class="light-blue--text">{{Status_Reason.Name}}</h4>
                      
                    <span v-if="EditingSection1"><v-combobox
                        v-model="Status_Reason"


                        :items="Status_ReasonOptions"
                        item-text="Name"
                        label="Status Reason Options"
                        hide-selected
                        small-chips
                        solo
                        flat
                      ></v-combobox>
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
    
          
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import LookupComponent from '@/components/LookupComponent'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
  LookupComponent,
},
    

  data() {
    return {
  
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      
      selectedEntity: {Name: 'Enquiries',
      PrimaryField: 'Name',
      Tables: [
          ]
      },
      selectedRecord: {},
      BasicEnquiry1000001: '',
      ActiveProcess: '',
      ActiveProcessDocid: '',
      EntityProcesses: [
  {
    "ProcessStages": [
      {
        "IsHeader": true,
        "Type": "Stage",
        "Automations": [],
        "Name": "Incoming",
        "Number": 1000001,
        "Steps": []
      },
      {
        "Steps": [],
        "Type": "Stage",
        "Automations": [],
        "Number": 1000002,
        "Name": "In Progress",
        "IsHeader": true
      },
      {
        "IsHeader": true,
        "Type": "Stage",
        "Name": "Completed",
        "Number": 1000003,
        "Steps": [],
        "Automations": []
      }
    ],
    "DisplayName": "Basic Enquiry",
    "ProcessStageInteger": 1000003,
    "id": "1000001",
    "Name": "Basic Enquiry",
    "IsHeader": true,
    "Number": 1000001,
    "Automations": [],
    "Type": "Process"
  }
],
      SelectProcessDialog: false,
      
      BannerTabs: [
        ],
      EditingSection1: false,
      TicketLog: false,
      step: 1,
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      Button1001002Buttondialog: false,
      StepNumber1001002dialog: false,
      CreateSalesOrdersnackbar: false,
      ProcessCreateSalesOrderCount: 0,
      NewVehicleFormID: '',
      NewVehicleFormObject: '',
      CreateSalesOrderdialog: false,
      CreateSalesOrderTitle: '',
      StageID: 1,
      Name: '',
      Surname: '',
      Enquiry: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {ID: 1000001,Name: 'Active' },
      Created_On: '',
      Created_Onmenu : false,
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Owner:  {id: '', Full_Name: ''},
      UnpermittedOwner:  {id: '', Full_Name: ''},
      HasOwner: false,
      OwnerRelatedParentObj: {},
      Email:  '',
      Telephone_Number:  '',
      Created_ByFull_Name: null,
      OwnerFull_Name: null,
      
      Created_Onmenu: false,
      
      StatusOptions: [{
  "Name": "Active",
  "ID": 1000001
},{
  "ID": 1000002,
  "Name": "InActive"
}],
      Statusfilter: '',Status_ReasonOptions: [{
  "Name": "Active",
  "ID": 1000001
},{
  "Name": "InActive",
  "ID": 1000002
}],
      Status_Reasonfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      OwnerArray: [],
      Ownerfilter: {Full_Name: ''},
      
      EditingEnquiry: false,
      dialog: false,
      initialize: '',
      search: '',
      EnquiryData: {},
      headers: [
      
        
        { text: 'Name', value: 'Name'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        { text: 'Status Reason', value: 'Status_Reason.Name'},
        
        
        
        { text: 'Owner', value: 'Owner.Full_Name'},
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedEnquirysnackbar: false,
      EnquiryEditdialog: false,
      newEnquirydialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
this.GetUsers()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
    else{
      return this.userIsAdmin
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },
          
    Validates1001002(){
      if(this.Status && this.Owner){
        return true
      }
      else{
        return false
      }
    },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    }
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              if(vm.UserRecord !== 'undefined'){
                vm.GetEnquiry(vm.UserRecord)
                      
              let UsersCreated_ByQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
                }
              }
              

                    
                      
              let UsersOwnerQuery = db.collection('users')
              if(typeof AdminTest !== 'undefined'){
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                 vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
                }
              }
              

                    
              }
              
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetEnquiry() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
              if(vm.UserRecord){
                    vm.CanEdit = true
                  }
            db.collection('enquiries').doc(this.$route.params.id).onSnapshot(snapshot => {
            var enquiry = snapshot.data()
            enquiry.id = this.$route.params.id
            
            this.selectedRecord = {id: this.$route.params.id, Name: enquiry.Name }
            if(enquiry.ProcessCreateSalesOrderCount){
               this.ProcessCreateSalesOrderCount = enquiry.ProcessCreateSalesOrderCount
            }
            if(enquiry.CreateSalesOrderProcessed){
              this.CreateSalesOrderProcessed = enquiry.CreateSalesOrderProcessed
            }
            this.RelatedObj = {id: this.$route.params.id, Name: enquiry.Name,identifier: 'Name',collection: 'enquiries',Link:'Enquiry' }
            this.EnquiryData = enquiry
            this.GetProcessData()
            this.EnquiryData.id = this.$route.params.id
            this.$emit('PushRecordName',this.EnquiryData.Name)
            if(typeof enquiry.Owner !== 'undefined'){
              let tempOwner = enquiry.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof enquiry.Owner !== 'undefined'){
              this.Business_Unit = enquiry.Business_Unit
            }        

            this.Name = enquiry.Name
            this.editedItem.Name = enquiry.Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.Surname = enquiry.Surname
            this.editedItem.Surname = enquiry.Surname
            //I think API Trigger should be here, outside the first if loop on fields
            this.Enquiry = enquiry.Enquiry
            this.editedItem.Enquiry = enquiry.Enquiry
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status = enquiry.Status
            this.editedItem.Status = enquiry.Status
            //I think API Trigger should be here, outside the first if loop on fields
            this.Status_Reason = enquiry.Status_Reason
            this.editedItem.Status_Reason = enquiry.Status_Reason
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(enquiry.Created_On && typeof enquiry.Created_On !== 'string'){
            this.Created_On = format(enquiry.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = enquiry.Created_On
              }
            if(enquiry.Created_By){
            this.Created_ByRelatedParentObj = {id: enquiry.Created_By.id, Name: enquiry.Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Created_By = this.Created_ByArray.find(created_by => created_by.id === enquiry.Created_Byid)
            if(typeof enquiry.Created_Byid !== 'undefined' && enquiry.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: enquiry.Created_Byid,Full_Name: enquiry.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = enquiry.Created_By
            if(enquiry.Owner){
            this.OwnerRelatedParentObj = {id: enquiry.Owner.id, Name: enquiry.Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Owner = this.OwnerArray.find(owner => owner.id === enquiry.Ownerid)
            if(typeof enquiry.Ownerid !== 'undefined' && enquiry.Ownerid !== ''){
              this.HasOwner = true
              if(typeof this.Owner === 'undefined'){
                this.UnpermittedOwner = {id: enquiry.Ownerid,Full_Name: enquiry.Owner.Full_Name}
              }
            }
            this.editedItem.Owner = enquiry.Owner
            this.Email = enquiry.Email
            this.editedItem.Email = enquiry.Email
            //I think API Trigger should be here, outside the first if loop on fields
            this.Telephone_Number = enquiry.Telephone_Number
            this.editedItem.Telephone_Number = enquiry.Telephone_Number
            //I think API Trigger should be here, outside the first if loop on fields
    
              if (typeof enquiry.stageid !== 'undefined'){
                this.StageID = enquiry.stageid
              }
            })
          
        },
      
      


    
      
      
            ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateEnquiry(){
        this.UndefinedMandatoryFields = []
      const EditedEnquiry = {
    Name: this.Name,
    Status: this.Status,
    Status_Reason: this.Status_Reason,
    
        }
      for(var prop in EditedEnquiry) {
          if (EditedEnquiry.hasOwnProperty(prop)) {
            if(typeof EditedEnquiry[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
        if(this.Validates1001002){
          this.CreateSalesOrderTitle = 'Order for '+' '+this.Name,
          this.StepNumber1001002dialog = true
        }
      db.collection('enquiries').doc(this.$route.params.id).update({
        Name: EditedEnquiry.Name,
        Status: EditedEnquiry.Status,
        Status_Reason: EditedEnquiry.Status_Reason,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedEnquirysnackbar = true
              this.CancelEnquiryEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Name !== 'undefined'){                  
                  let NameQuery = []
                  let NameStringArray = this.Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(NameStringArray.length,NameStringArray)
                    for (p = 0; p < NameStringArray.length; p++) {
                        buildup = buildup+ NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    NameQuery = lowertext.split(',')  
                    NameQuery.length = NameQuery.length-1
                   db.collection('enquiries').doc(this.$route.params.id).update({
                     NameQuery: NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.Surname !== 'undefined'){
                   db.collection('enquiries').doc(this.$route.params.id).update({
                     Surname: this.Surname,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Enquiry !== 'undefined'){
                   db.collection('enquiries').doc(this.$route.params.id).update({
                     Enquiry: this.Enquiry,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Email !== 'undefined'){
                   db.collection('enquiries').doc(this.$route.params.id).update({
                     Email: this.Email,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Telephone_Number !== 'undefined'){
                   db.collection('enquiries').doc(this.$route.params.id).update({
                     Telephone_Number: this.Telephone_Number,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.EnquiriesArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('enquiries').doc(item.id).delete() && this.EnquiriesArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.EnquiriesArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelEnquiryEditing(){
      this.EditingEnquiry = false
    },
    EditEnquiry() {
      this.EditingEnquiry = !this.EditingEnquiry
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        return vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.EnquiryData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },

  GetUsersOwner() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.OwnerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersOwnerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.OwnerArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.OwnerArray = vm.UsersStore
                        return vm.GetOwner();
                        })
    }
  },

  GetOwner(){
    this.Owner = this.OwnerArray.find(owner => owner.id === this.EnquiryData.Ownerid)
  },

  //GetUsersOwner(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.OwnerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersOwner finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},


PrepareLinkExistingOwner(){
this.CancelAddParentDialog()
this.PrepareDataParentOwner()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Owner'
      this.LookupArray = this.OwnerArray
      this.LookupArrayHeaders = [
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentOwner(){
  if(!this.Business_UnitArray || this.Business_UnitArray.length === 0){
  let Business_Unitquery = db.collection('businessunits')
  Business_Unitquery.onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  }
  this.ParentTitle = 'Owner'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Owner', Prop: 'Full_Name', Propid: 'Ownerid', Value: '',Array: 'OwnerArray',LookupFieldName: 'Owner'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Owner', Prop: 'Owner', Value: '',LookupFieldName: 'Owner'}
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )     
  
},

ActivateAddNewOwnerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentOwner()
},



      
    
      
      Button1001002Button(){
        
        if(this.Validates1001002){
          this.CreateSalesOrderTitle = 'Order for '+' '+this.Name,
          this.StepNumber1001002dialog = true
        }
        else{
          alert('Conditions not met to Process Step Number 1001002.1')
        }
      },
      
    
      
    
      
    
      
    CreateSalesOrder1000102(){
      

        let NewVehicleForm = {
          Title: 'Order for '+this.Name,
        }
          NewVehicleForm.Created_By = {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}
          NewVehicleForm.Created_Byid = this.UserRecord.id
          NewVehicleForm.Created_On = new Date()
        console.log(NewVehicleForm)
        
        let vm = this
        db.collection('vehicleforms').add(NewVehicleForm).then(NewVehicleFormdoc => {
            vm.NewVehicleFormID = NewVehicleFormdoc.id
            db.collection('vehicleforms').doc(vm.NewVehicleFormID).onSnapshot(snapshot => {
              let NewVehicleFormdocdata = snapshot.data()            
            vm.NewVehicleFormObject = NewVehicleFormdocdata
            vm.NewVehicleFormObject.id = vm.NewVehicleFormID
            })
            vm.CreateSalesOrdersnackbar = true
        })
        this.ProcessCreateSalesOrderCount = this.ProcessCreateSalesOrderCount-1+2
        db.collection('enquiries').doc(this.$route.params.id).update({
          CreateSalesOrderProcessed: true,
          ProcessCreateSalesOrderCount: this.ProcessCreateSalesOrderCount,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
        })
        },
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          
      
     
      
      

      
      
      
      ResetFilters() {},
      
      
      
    ProcessDeleteItem(item,itemarrayname,collection){
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
      
            GetProcessData(){
        
        if(this.EnquiryData.ActiveProcess){
            this.ActiveProcess = this.EnquiryData.ActiveProcess
            this.BasicEnquiry1000001 = this.EnquiryData.BasicEnquiry1000001
            this.ActiveProcessDocid = this.EnquiryData.ActiveProcessDocid
            let ProcessObj = this.EntityProcesses.find(obj => obj.Number == this.ActiveProcess.Number)
            this.ProcessStages = ProcessObj.ProcessStages
            db.collection('enquiries').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            this.StageName = stagedata.StageName
            if(this.StageName){
            this.StageID = stagedata.StageName.Number
            }
            })
            }
      },
      AssignProcess(){
      this.SelectProcessDialog = true
    },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
      db.collection('enquiries').doc(this.$route.params.id).update({
        ActiveProcess: this.ActiveProcess
      })
      let processdoc = this.ActiveProcess.Name.split(' ').join('')+this.ActiveProcess.Number
      console.log(processdoc)
      console.log(typeof this[processdoc],this[processdoc])
      console.log(!this[processdoc])
      if(!this[processdoc]){
        // let ProcessDoc = 
        db.collection('enquiries').doc(this.$route.params.id).collection('processes').add(this.ActiveProcess).then(doc => {
          db.collection('enquiries').doc(this.$route.params.id).update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
        })
        this.StageID = 0
        this.StageName = ''
      }
      else{
        db.collection('enquiries').doc(this.$route.params.id).collection('processes').doc(this[processdoc]).update({
         DisplayName: this.ActiveProcess.DisplayName,
         Name: this.ActiveProcess.Name,
         ID: this.ActiveProcess.Number,
          ProcessStages: this.ActiveProcess.ProcessStages
        })
        db.collection('enquiries').doc(this.$route.params.id).update({
            ActiveProcessDocid: this[processdoc]
          })
          this.ActiveProcessDocid = this[processdoc]
          db.collection('enquiries').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            this.StageName = stagedata.StageName
            if(this.StageName){
            this.StageID = stagedata.StageName.Number
            }
            })
      }
    },
    updateStatus(StageID){
          let Stage = this.ActiveProcess.ProcessStages.find(obj => obj.Number == StageID)
          setTimeout(() => {
          db.collection('enquiries').doc(this.$route.params.id).collection('processes').doc(this.ActiveProcessDocid).update({
            StageName: Stage,
            StageID: this.StageID,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
          })
          db.collection('enquiries').doc(this.$route.params.id).update({
            stagename: Stage,
            stagenameid: this.StageID,
          })
          
        }, 300)
        },



    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
      
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        